<template>
  <section
    class="box-height bonusModel"
    style="height: 100%; padding: 0 15px !important"
  >
    <div style="background-color: #fff; padding-top: 20px">
      <el-form :model="filter" ref="filter" label-width="100px">
        <el-row>
          <div>
            <el-form-item label="模型名称：" prop="modelIds" class="fl">
              <el-select
                v-model="filter.modelIds"
                placeholder="请选择"
                filterable
                clearable
                collapse-tags
                multiple
                @change="search"
              >
                <el-option
                  v-for="(item, index) in nameList"
                  :key="'name_' + index"
                  :label="item.modelName"
                  :value="item.modelId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="顾问类型：" prop="consultantTypes" class="fl">
              <el-select
                v-model="filter.consultantTypes"
                placeholder="请选择"
                filterable
                clearable
                collapse-tags
                multiple
                @change="search"
              >
                <el-option
                  v-for="(item, index) in typeList"
                  :key="'type_' + index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-button
              type="primary"
              size="mini"
              @click="showDig"
              style="margin-left: 15px"
              >新增模型</el-button
            >
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <div class="pd40" style="background-color: #fff; padding: 0px 0 40px">
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor"
        >
          <el-table-column prop="modelName" label="模型名称"> </el-table-column>
          <el-table-column prop="consultantType" label="顾问类型">
          </el-table-column>
          <el-table-column prop="goal" label="全年目标业绩">
            <template #default="scope">
              {{ scope.row.goal ? scope.row.goal + "万" : "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="baseRatio" label="基础比例">
            <template #default="scope">
              {{ scope.row.baseRatio ? scope.row.baseRatio + "%" : "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="relatedConsultantCount" label="关联顾问">
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间">
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template #default="scope">
              <p @click="handleEdit(scope.row.id)" class="tabHref">编辑模型</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </div>
      <el-drawer
        :visible.sync="drawerVisible"
        custom-class="drawerClass"
        direction="ttb"
        :modal="false"
        append-to-body
        :before-close="handleClose"
      >
        <div slot="title" class="drawerTitle">
          {{ drawerTitle }}
        </div>
        <div class="drawerCon">
          <div class="drawerCon_from" v-if="drawerVisible">
            <el-form
              @submit.native.prevent
              :model="formdata"
              ref="formdata"
              :rules="formRules"
              :validate-on-rule-change="false"
              v-loading="formLoading"
              label-width="120px"
              label-position="right"
              class="addOrEditPro"
            >
              <p class="drawerCon_from_tit">基础信息</p>
              <el-row :gutter="24">
                <el-col :span="7">
                  <el-form-item label="顾问类型：" prop="consultantType">
                    <el-select
                      v-model="formdata.consultantType"
                      placeholder="请选择"
                      filterable
                      clearable
                      @change="handleConsultantType"
                    >
                      <el-option
                        v-for="(item, index) in typeList"
                        :key="'type_' + index"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="城市等级：" prop="cityType">
                    <el-select
                      v-model="formdata.cityType"
                      placeholder="请选择"
                      filterable
                      clearable
                      @change="handleCityType"
                    >
                      <el-option value="一线" label="一线"> </el-option>
                      <el-option value="二线" label="二线"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="适用年份：" prop="yearValue">
                    <el-input
                      placeholder="请输入适用年份"
                      maxlength="20"
                      v-model="formdata.yearValue"
                      @blur="handleYearValue"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item
                    label="模型名称："
                    prop="modelName"
                    class="is-required"
                  >
                    <el-input
                      placeholder="请输入模型名称"
                      maxlength="50"
                      v-model="formdata.modelName"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <p
                class="drawerCon_from_tit"
                v-if="
                  formdata.consultantType !== '实习生' &&
                  formdata.consultantType !== '合伙人' &&
                  formdata.consultantType !== '其他'
                "
              >
                计算规则
              </p>
              <el-row
                :gutter="24"
                v-if="
                  formdata.consultantType !== '实习生' &&
                  formdata.consultantType !== '合伙人' &&
                  formdata.consultantType !== '其他'
                "
              >
                <el-col :span="7">
                  <el-form-item label="全年目标业绩：" prop="goal">
                    <el-input
                      placeholder="只填写数字即可"
                      v-model="formdata.goal"
                      type="number"
                    >
                      <template slot="append">万</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="基础比例：" prop="baseRatio">
                    <el-input
                      placeholder="只填写数字即可"
                      :max="100"
                      v-model.number="formdata.baseRatio"
                    >
                      <template slot="append">%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="drawerCon_footer fixedFoot">
            <el-button @click="handleClose" size="small">取 消</el-button>
            <el-button
              type="primary"
              :loading="saveloading"
              size="small"
              @click="save('formdata')"
              style="margin-right: 15px"
              >确 定</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {
  bonusCalculateModelPage, //奖金计算模型分页搜索
  bonusCalculateModelAddOrEdit, //奖金计算模型新增或编辑
  bonusCalculateModelEditData, //奖金计算模型获取编辑参数
  bonusCalculateModelOptionList, //获取奖金计算模型下拉列表
} from "../../api/api";
export default {
  data() {
    const intNum = (rule, value, callback) => {
      // 校验数字
      // let reg = /^[0-9]*$/;
      if (value && value > 100) {
        callback(new Error("最大比例为100"));
      } else {
        callback();
      }
    };
    return {
      filter: {
        modelIds: [],
        consultantTypes: [],
        page: {
          total: 0,
          current: 1,
          size: 50,
        },
      },
      typeList: ["SC", "C", "AC", "寻访员", "实习生", "合伙人", "其他"],
      ListData: [],
      listLoading: false,
      drawerVisible: false,
      saveloading: false,
      formdata: {
        cityType: "",
        consultantType: "",
        goal: null,
        id: null,
        modelContent: null,
        modelName: null,
        baseRatio: null,
        yearValue: "",
      },
      formRules: {
        consultantType: [
          { required: true, trigger: "change", message: "顾问类型不能为空" },
        ],
        goal: [
          { required: true, trigger: "blur", message: "全年目标业绩不能为空" },
          {
            pattern: /^\d{1,9}(\.\d{1,2})?$/,
            message: "精确到小数点后两位",
          },
        ],
        baseRatio: [
          { required: true, trigger: "blur", message: "基础比例不能为空" },
          {
            pattern: /^\d{1,9}(\.\d{1,2})?$/,
            message: "精确到小数点后两位",
          },
          { validator: intNum, trigger: "blur" },
        ],
      },
      formLoading: false,
      drawerTitle: "",
      nameList: [],
    };
  },
  created() {
    this.getList();
    this.getNameList();
  },
  methods: {
    //获取奖金计算模型下拉列表
    getNameList() {
      bonusCalculateModelOptionList().then((res) => {
        if (res.success) {
          this.nameList = res.result;
        }
      });
    },
    //搜索
    search() {
      this.filter.page.current = 1;
      this.filter.page.total = 0;
      this.getList();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.getList();
    },
    //获取列表数据
    getList() {
      this.listLoading = true;
      bonusCalculateModelPage(this.filter).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //新增
    showDig() {
      this.drawerVisible = true;
      this.drawerTitle = "新增模型";
    },
    //编辑
    handleEdit(id) {
      this.drawerVisible = true;
      this.drawerTitle = "编辑模型";
      bonusCalculateModelEditData({ id: id }).then((res) => {
        if (res.success) {
          this.formdata = res.result;
        }
      });
    },
    //关闭弹窗
    handleClose() {
      this.formdata = {
        cityType: "",
        consultantType: "",
        goal: null,
        id: null,
        modelContent: null,
        modelName: null,
        baseRatio: null,
        rules: [
          {
            calculateType: "阶梯计算",
            goal: null,
            id: null,
            includeMaxTarget: true,
            includeMinTarget: true,
            items: [
              {
                amountCalculateType: null,
                bonusRatio: null,
                id: null,
                ruleId: null,
              },
            ],
            maxTargetType: "实际目标业绩",
            maxTargetValue: null,
            minTargetType: "实际目标业绩",
            minTargetValue: null,
            modelId: null,
          },
        ],
        yearValue: "",
      };
      this.drawerVisible = false;
    },
    handleConsultantType(val) {
      this.formdata.consultantType = val;
      this.setModelName();
    },
    handleCityType(val) {
      this.formdata.cityType = val;
      this.setModelName();
    },
    handleYearValue(val) {
      this.setModelName();
    },
    //模型名称
    setModelName() {
      this.formdata.modelName =
        this.formdata.consultantType +
        this.formdata.cityType +
        (this.formdata.yearValue.length
          ? "（" + this.formdata.yearValue + "）"
          : "");
    },
    //添加规则
    addRules() {
      let item = {
        calculateType: "阶梯计算",
        goal: null,
        id: null,
        includeMaxTarget: true,
        includeMinTarget: true,
        baseRatio: null,
        items: [
          {
            amountCalculateType: null,
            bonusRatio: null,
            id: null,
            ruleId: null,
          },
        ],
        maxTargetType: "实际目标业绩",
        maxTargetValue: null,
        minTargetType: "实际目标业绩",
        minTargetValue: null,
        modelId: null,
      };
      this.formdata.rules.push(item);
    },
    //删除规则
    delectRules(index) {
      this.formdata.rules.splice(index, 1);
    },
    //添加计算额
    addNewAmountCalculate(index) {
      let item = {
        amountCalculateType: null,
        bonusRatio: null,
        id: null,
        ruleId: null,
      };
      this.formdata.rules[index].items.push(item);
    },
    //删除规则
    delectAmountCalculate(index, index1) {
      this.formdata.rules[index].items.splice(index1, 1);
    },
    //保存
    save(formName) {
      this.saveloading = true;
      return new Promise((resole) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            bonusCalculateModelAddOrEdit(this.formdata).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.handleClose();
                this.search();
                resole();
              }
            });
          }
          this.saveloading = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flexRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.rulesContent {
  margin: 0 15px 15px;
  padding: 10px;
  border: 1px #ececec dotted;
}

.rulesContentBottom {
  margin: 20px;
}

.tit {
  border: 1px #ececec solid;
  border-radius: 5px;
  padding: 2px;
  min-width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin: 3px 10px;
}
</style>
